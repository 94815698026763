@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import url("results.css");
@import url("view.css");
@import url("savedGames.css");

body {
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 2em;
    width: max-content;
}

body.results-page {
    /* differnt margin is needed for the results page on mobile */
    margin: 0 !important;
}

.view-page #app {
    /* necessary for the .spacer element on the view page to allow scrolling past the content */
    min-height: calc(100vh + 50vh);
    margin-right: 32px;
}

i.bi {
    margin-right: 5px;
}

button.btn {
    transition: all 0.2s ease-in-out;
}

button.btn:hover {
    transform: scale(0.98);
}

button.btn:active {
    transform: scale(0.95);
}

th {
    font-weight: bold;
}

th[scope="col"] {
    font-size: larger;
}

.player-table {
    max-width: 10%;
    float: left;
}

.player-table thead {
    top: 0;
    z-index: 10;
}

.player-table .first-header {
    font-size: 18px;
}

.player-table .second-header {
    /* reverse font-size: larger on the <thead> */
    font-size: smaller;
}

table.mismatch-table {
    /* overwrite styles from .player-table */
    max-width: fit-content;
}

table.mismatch-table td,
table.mismatch-table th {
    /* overwrite styles from .player-table */
    border: 0px none black !important;
    font-size: 0.875em;
    color: var(--bs-form-invalid-color);
}

table.player-table tr>td:last-child,
table.player-table tr>th:last-child,
.called-tricks-col {
    border-right: 3px solid black;
}

input[type="number"] {
    width: 4em;
}

.player-buttons {
    right: 0px;
    top: 25%;
    opacity: 0;
    transition: all 0.1s;
    cursor: pointer;
    padding-left: 5px;
    /* same color as the table header background */
    background-color: var(--bs-table-bg);
}

.player-buttons i.bi {
    margin-right: 10px;
}

.player-name:hover .player-buttons {
    opacity: 1;
    right: 5px;

    transition: all 0.2s;
}

#sync-loading-spinner {
    --size: 1em;
    width: var(--size);
    height: var(--size);
}

.ws-status {
    font-size: 1em;
}

.copy-btn,
.info-btn,
.back-button {
    cursor: pointer;
}

.alert {
    /* to counter the mt-3 from the player table */
    margin-bottom: -0.5rem;
}

.game-saved-close-btn {
    position: absolute;
    right: 20px;
    cursor: pointer;
}