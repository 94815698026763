p.card-text {
    margin-bottom: 0.5rem;
}

.game-card {
    cursor: pointer;
    transition: all 0.2s;
    /* .game-card is a <a>, so remove the default underline from it */
    text-decoration: none;
}

.saved-game-list-loading .game-card {
    /* disable clicking the link */
    pointer-events: none;
}

.saved-game-list {
    opacity: 1;
    transition: all 0.3s;
}

.saved-game-list-loading {
    opacity: 0.35;
}

.game-card:hover {
    transform: scale(1.05);
}

.leaderboard-player-toggle {
    color: black;
    margin-left: 5px;
}

.around-explanation {
    font-size: 0.9rem;
}

.multiSelect-container i.bi.multi-select-remove-icon {
    margin-right: 0;
    margin-left: 5px;
    cursor: pointer;
}

.multiSelect-container input.checkbox {
    width: 16px;
    height: 16px;
}

.multiSelect-container img.icon_cancel {
    width: 26px;
    height: 26px;
}

.multiSelect-container li.option:hover {
    background-color: var(--bs-secondary-bg);
    color: black;
}

.multiSelect-container span.chip {
    background-color: var(--bs-primary);
}